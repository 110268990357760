import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
`;

const iconPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];


function PokerChallenge(props) {
    const [flipped, setFlipped] = useState(false);
    const [myVote, setMyVote] = useState(-1);
    const [votesState, setVotesState] = useState({})
    const [waiting, setWaiting] = useState(false)

    useEffect(() => {
        if (props.socketData && props.socketData.hasOwnProperty("type")){
            if(props.socketData.type === "chal update") {
                const userIcon = parseInt(props.socketData.userIcon);
                const userInput = parseInt(props.userInput);
                let newState = votesState;
                newState[userIcon] = userInput
                setVotesState(newState)

            }
            if(props.socketData.type === "input results"){
                setWaiting(false)
                setFlipped(true)
            }
        }
      }, [props.socketData]);

    useEffect(() => {
        let votesStateInit = {}
        props.players.forEach((player)=>{
            votesStateInit[player.iconId] = "";
        })
        setVotesState(votesStateInit)
    }, [])


    function highlight(text){
        const groupNameStyle = {color: "var(--baseColor)" }
        const playerNameStyle = {color: props.playerColor }
        text = text.replace(/<any>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Anyone</span>`);
        text = text.replace(/<all>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Everyone</span>`);
        return text.replace(/<name>/g,`<span style="${Object.entries(playerNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">${props.turnPlayerName}</span>`);
    }

    function sendMyVote(e){
        e.preventDefault();
        const myVote = e.target.id;
        if(myVote===""){
            return
        }
        setMyVote(myVote);
        const myIcon = props.players.find(u => u.uid === props.yourId).iconId
        let payload = {}
        payload['userIcon'] = myIcon
        payload['userInput'] = myVote
        payload['displayType'] = "highlow"
        //payload['updateStatus'] = true
        props.sendEvent("input submitted", payload)
        setWaiting(true);
    }

    function getCardSource(cardIndex){
        return `/cards/${props.varData[cardIndex]}_of_clubs.png`
    }


    function renderButtons(){
        if(flipped && props.turnPlayer === props.yourId){
            return <button className='splashNavBtn' onClick={props.handleClose}>
                END TURN
            </button>
        }
        if(flipped && props.turnPlayer !== props.yourId){
            return null;
        }
        //TODO: Add onClick to Arrows
        return <div className='pokerBtnContainer'>
        <button id='higher' className='pokerVoteBtn' onClick={waiting?null:sendMyVote} style={{background: waiting?"darkgray":"black", color: waiting && myVote==='lower'?'lightgray':'white'}}>
            Higher
        </button>
        <button id='lower' className='pokerVoteBtn' onClick={waiting?null:sendMyVote} style={{background: waiting?"darkgray":"black", color: waiting && myVote==='higher'?'lightgray':'white'}}>
            Lower
        </button>
    </div>
    }

    function renderCard(cardIndex){
        return <div class= {'pokerFlipCard'+ (cardIndex==1 && !flipped?' flipped':'')}
        style={{animation: `zoomText .15s linear ${cardIndex==0?'.3':'.6'}s 1 forwards`}}>
        <div class="pokerFlipCard-inner">
          <div class="pokerFlipCard-back"
          style={{backgroundColor: "#151515"}}>
                  <img src="/icons/portrait4x.png" alt="logo" class="mineLogo"/>
          </div>
          <div class="pokerFlipCard-front">
                <img
                    src={getCardSource(cardIndex)}
                    className='pokerCardImage'                  
                />
          </div>

        </div>
      </div>
    }

    function getPromptColor(){
        const isHigher = props.varData[1] > props.varData[0]
        if(myVote===-1 || waiting){
            return ""
        }
        if(isHigher){
            if(myVote==="higher"){
                return "lime"
            }
            if(myVote==="lower"){
                return "#FF3333"
            }
        } else {
            if(myVote==="higher"){
                return "#FF3333"
            }
            if(myVote==="lower"){
                return "#lime"
            }
        }
    }

    function renderPrompt(){
        const isHigher = props.varData[1] > props.varData[0]
        if(myVote===-1){
            return "<all>, is the next card higher or lower?"
        }
        if(waiting){
            return "Waiting for other players..."
        }
        if(isHigher){
            if(myVote==="higher"){
                return "Correct, pick someone to drink!"
            }
            if(myVote==="lower"){
                return "Wrong, take a drink!"
            }
        } else {
            if(myVote==="higher"){
                return "Wrong, take a drink!"
            }
            if(myVote==="lower"){
                return "Correct, pick someone to drink!"
            }
        }
    }

    function renderPageContent(){
        return <>
            <div className='pokerPrompt' style={{color: getPromptColor()?getPromptColor():''}}>
                <p dangerouslySetInnerHTML={{__html: highlight(renderPrompt())}}>
                </p>
            </div>
            <div className='pokerContainer'>
                <div className='pokerCardContainer'>
                    {renderCard(0)}
                </div>
                <div className='pokerCardContainer'>
                    {renderCard(1)}
                </div>
            </div>        
            {renderButtons()}
        </>
    }


    window.scrollTo(0, 0);
    return (
    <Page className="challengeContainer" style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
        {renderPageContent()}
    </Page>
    );
}

export default PokerChallenge;