import React, { useState, useEffect, useRef } from 'react';

const Odometer = ({ number }) => {
  const [prevNumber, setPrevNumber] = useState(number);
  const [currentNumber, setCurrentNumber] = useState(number);
  const [animation, setAnimation] = useState(false);
  const [glow, setGlow] = useState(false)
  const queue = useRef([]); // Ref to keep track of number updates

  useEffect(() => {
    if (number !== currentNumber) {
      queue.current.push(number); // Add new number to queue
      if (!animation) { // Only start animation if no animation is in progress
        processQueue();
      }
    }
  }, [number]);

  useEffect(() => {
    if (!animation && queue.current.length > 0) {
      processQueue();
    }
  }, [animation]);

  const processQueue = () => {
    if (queue.current.length > 0) {
      const nextNumber = queue.current.shift(); // Get the next number from the queue
      setPrevNumber(currentNumber);
      setCurrentNumber(nextNumber);
      setAnimation(true);
      setGlow(true)

      setTimeout(() => {
        setPrevNumber(nextNumber);
        setAnimation(false);

      }, 1000); // Adjust this delay to match the duration of your animation
    }
  };

  return (
    <div className="odometer">
      <div className={`odNumber ${animation ? 'scroll' : ''}`}>
        <div className="odNumber-item">{prevNumber}</div>
        <div className="odNumber-item">{currentNumber}</div>
      </div>
    </div>
  );
};

export default Odometer;
