import React, { useState, useRef, useEffect, useMemo} from 'react';
import JoinForm from "./components/UsernameForm";
import Lobby from "./components/Lobby"
import GameBoard from './components/GameBoard';
import HappyHour from './components/HappyHour';
import io from "socket.io-client";
import './App.css';
import Limbo from './components/Limbo';
import WaitingRoom from './components/WaitingRoom';


const appVersion = "Pre-Release v0.9.9";
let ioServer = true;
let staging = false;
let paywall = false;
//const stagingAPI = "https://mysterious-cove-28437-82046c6cfeae.herokuapp.com/"

const stagingAPI = "http://18.224.141.37/"
const prodAPI = "https://pacific-everglades-44574.herokuapp.com/"
const drinkRoyaleApi = staging ? stagingAPI : prodAPI
//const drinkRoyaleApi = "ws://localhost:8080/"

function App() {
  const [username, setUsername] = useState("");  
  const [roomCode, setRoomCode] = useState("");
  const [urlCode, setUrlCode] = useState(false);
  const [backupHost, setBackupHost] = useState("");
  const [isHost, setIsHost] = useState(false);
  const [inGame, setInGame] = useState(false);
  const [connected, setConnected] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Early Access");
  const [lobby, setLobby] = useState({});
  const [availableIcons, setAvailableIcons] = useState([]);
  const [bannerMsg, setBannerMsg] = useState("Wait for next turn");
  const [library, setLibrary] = useState({});
  const [tileMap, setTileMap] = useState(null);
  const [tileData, setTileData] = useState(null);
  const [socketData, setSocketData] = useState(null);
  const [alertData, setAlertData] = useState("Reminder:\n If you leave this page, you'll miss a turn!\n\nCONTINUE");
  const [limbo, setLimbo] = useState(false);
  const [waitingRoom, setWaitingRoom] = useState(false);
  const [showLeaders, setShowLeaders] = useState(false);
  const [useOldRule, setUseOldRule] = useState(false);
  const [dLvl, setDLvl] = useState(1);
  const [sLvl, setSLvl] = useState(1);
  const [miniBoard, setMiniBoard] = useState(true);
  const [lvlsPicked, setlvlsPicked] = useState(false)
  const [splashes, setSplashes] = useState([]) 
  const [playersSwapped, setPlayersSwapped] = useState(false);
  const [happyHour, setHappyHour] = useState(true);
  const [miniGameStart, setMiniGameStart] = useState(null)
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
 const[floatAway,  setFloatAway] = useState(false);

const checkpointMsg = {
  50: "You made it to turn 50!\n Cheers!\n\nCONTINUE",
  75: "You made it to turn 75\n\nDon't be a hero\n\nCONTINUE",
  100: "You made it to turn 100...\n\nEverything okay bud?\n\nCONTINUE",
  125: "You made it to turn 125...\n\nTake a breather, champ\n\nCONTINUE",
  150: "Turn #150...\n\nuhhh\n\nCONTINUE",
  200: "Turn #200...\n\n I don't mean to be that guy but...\n\nCONTINUE",
  250: "Turn #250...\n\nLord have mercy\n\nCONTINUE"
}

/*
-1 - No Selection
0 - Bee
1 - Bull
2 - Robot
3 - Eagle
4 - Snake
5 - Cowboy
6 - Worm
7 - Queen
8 - Forklift
9 - Octopus 
10 - Lime
11 - Eggplant
*/

/*
  Flow to control lobby intake

  App user hits Create New Lobby -> Generating Lobby gif
    App sends a ping to the server saying lobbyVoucherRequested
    App will generate a lobby 'lobby voucher key', store it, and send it to the user
    When the user recieves the voucher, they get a link that says your lobby is ready -> START
    This start link will include the voucher key as a param

    On page load, if a param called voucher is present, create a websocket connection
    on connection, validateVoucher call
    If(error) display homePage with errorMsg
      Invalid lobby creation code
    if voucher matches
      remove voucher code from active pool
      send back a createLobby message

*/



const socketRef = useRef();
const lobbyRef = useRef(lobby);
const alertDataRef = useRef(alertData);
const roomCodeRef = useRef(roomCode);
const useOldRuleRef = useRef(useOldRule);
const showLeadersRef = useRef(showLeaders);
const tileMapRef = useRef(tileMap);
const waitingRoomRef = useRef(waitingRoom);
const limboRef = useRef(limbo);
const splashRef = useRef(splashes);
const miniGameStartRef = useRef(miniGameStart)

function triggerReconnection(){
  loadStaticData()
  const connectionId = localStorage.getItem('socketConnectionId');
  const connectionRoom = localStorage.getItem('socketRoomCode');
  console.log(connectionId)
  setRoomCode(connectionRoom)
  //TODO ws
  // const url = `${drinkRoyaleApi}?connectionId=${encodeURIComponent(connectionId)}`;
  // socketRef.current = new WebSocket(url);

//TODO socket io
  socketRef.current = io.connect(drinkRoyaleApi, {
    query: { connectionId },
  });
  assignOnMsg(socketRef);
  socketRef.current.emit("user reconnect", connectionRoom, connectionId, socketRef.current.id)
}

function findLowestSeatNum(arr) {
  // Initialize the lowestSeatNum with the maximum possible value
  let lowestSeatNum = Infinity;

  // Iterate through each object in the array
  arr.forEach(obj => {
    // Check if the object has a valid seatNum field and it's a number
    if (obj.hasOwnProperty('seatNum') && typeof obj.seatNum === 'number') {
      // Update the lowestSeatNum if the current seatNum is smaller
      lowestSeatNum = Math.min(lowestSeatNum, obj.seatNum);
    }
  });

  // Return the lowestSeatNum found
  return lowestSeatNum !== Infinity ? lowestSeatNum : null;
}

function getAndClearURLParams() {
  var params = {};
  var queryString = window.location.search.substring(1);
  var pairs = queryString.split('&');  
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
  }

  // Clear the URL parameters
  window.history.replaceState({}, document.title, window.location.pathname);

  return params;
}

function tryLobbyVoucher(voucherCode, name){
  if(!socketRef.current){
    socketRef.current = io.connect(drinkRoyaleApi);
    assignOnMsg(socketRef)
  }
  setUsername(name);

  socketRef.current.emit("voucher attempt", voucherCode, name, appVersion, (res)=>createResponseHandler(res));
}
  
  useEffect(() =>{
    let urlParams = getAndClearURLParams()
    //First Attempt Voucher
    if(urlParams.hasOwnProperty("voucher") && urlParams.hasOwnProperty("name")){
      tryLobbyVoucher(urlParams.voucher, urlParams.name)
    } else if(urlParams.hasOwnProperty("room")){
      console.log(urlParams)
      setRoomCode(urlParams.room.toUpperCase())
      setUrlCode(true)
    }else{
      //Then Attempt a reconnect

      //localStorage.removeItem('socketConnectionId'); localStorage.removeItem('socketRoomCode');
      //on page load (or reload)
      const connectionId = localStorage.getItem('socketConnectionId');
      const connectionRoom = localStorage.getItem('socketRoomCode');  
      if (connectionId && connectionRoom) {
        triggerReconnection()
      }
      loadStaticData()
    }
    //Establish heartbeat
    var lastFired = new Date().getTime();
    setInterval(function(){
      //Timing mechanism
      var now = new Date().getTime();
      if(now-lastFired > 1500 && lobby && lobby['eid']>0){
        setLimbo(true)
      }
      lastFired = now;
    }, 1000)

    // Function to update window dimensions
    const updateWindowDimensions = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener to update dimensions when window is resized
    window.addEventListener('resize', updateWindowDimensions);

    const handleVisibilityChange = () => {
      if(document.visibilityState === "visible"){
        if (lobbyRef.current) {
          if(lobbyRef.current.inGame){
            //In game, returning from hidden page
            triggerReconnection()
          } else {
            if(!socketRef.current){
              //Not In Game, returning from hidden page
              localStorage.removeItem('socketConnectionId'); 
              localStorage.removeItem('socketRoomCode');
              window.location.reload();
            }
          }
        }
      }
      if (document.visibilityState === "hidden") {
        //Anyone leaving anytime loses their socket. Returning will be handled conditionally
        if(socketRef.current){
          socketRef.current.disconnect()
        }
        socketRef.current = null 
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  },[])

  useEffect(()=>{
    splashRef.current = splashes
},[splashes])


  useEffect(()=>{
      waitingRoomRef.current = waitingRoom
  },[waitingRoom])

  useEffect(()=>{
    limboRef.current = limbo
  },[limbo])

  useEffect(() => {
    lobbyRef.current = lobby;
}, [lobby]);

useEffect(() => {
  roomCodeRef.current = roomCode;
}, [roomCode]);


useEffect(() => {
  if(miniGameStart){
    miniGameStartRef.current = miniGameStart;
  }
}, [miniGameStart]);

useEffect(() => {
  if(alertData){
    alertDataRef.current = alertData;
  }
}, [alertData]);

useEffect(() => {
  useOldRuleRef.current = useOldRule; 
}, [useOldRule]);

useEffect(() => {
  tileMapRef.current = tileMap; 
}, [tileMap]);

useEffect(() => {
  showLeadersRef.current = showLeaders; 
}, [showLeaders]);

useEffect(() => {
  if(floatAway){
    setTimeout(() => {
      setConnected(true);
  },1300);
  }
}, [floatAway])

  //Join trigger for host
  useEffect(() => {
    if(isHost){
      joinRoom(isHost);
    }
    if(roomCode){
      localStorage.setItem('socketRoomCode', roomCode)
    }
  },[roomCode])
  
  //Join trigger for everyone else
  useEffect(() => {
    if(floatAway || connected){
      joinRoom(false)
    }
  },[validated])

  useEffect(() => {
    
  }, [inGame])

  useEffect(()=>{
    if(lobby && lobby.hasOwnProperty('eid') && lobby['eid']===0){
      setInGame(true);
      let lob = lobbyRef.current
      lob['eid'] = 1;
      setLobby(lob)

      startNewTurn(findLowestSeatNum(lob.players));
    }
  },[lobby])

  function chooseBackupHost(e){
    e.preventDefault()
    if(username==="Nubjug"){
      setBackupHost(e.target.id)
      console.log("howdy")
    }
  }

  function loadStaticData(){
    console.log("loading static")
    async function fetchGameLibrary() {
      const response = await fetch("./eventLibrary.json");
      const data = await response.json();
      setLibrary(data['library']);
    }
    async function fetchMapData() {
      const response = await fetch("./map.json");
      const data = await response.json();
      setTileMap(data)
    }
      const preloadImages = async () => {
        const splashNames = ["Take Drinks","Give Drinks","Drink If","Solo","All Play","Duel","Rule","Hot Seat","Dare","Chance", "Bad Luck", "End","Start"]
        let splashImages = []
        splashNames.forEach((path)=>{
            splashImages.push(<img className='splashArt' src={`./splashes/ani_${path}.png`} alt='splashArt' />)
        })
        setSplashes(splashImages)
    };

      preloadImages();
      fetchGameLibrary();
      fetchMapData();
  }

  function detatchFromLobby(e){
    e.preventDefault()
    localStorage.removeItem('socketConnectionId'); 
    localStorage.removeItem('socketRoomCode');
    window.location.reload();
  }

  function assignWSOnMsg(ws){
    ws.onmessage = (msg) => {
      const data = msg.data;
      const parts = data.split(":");
      const action = parts[0];
      const content = parts[1];
      switch(action){
        case "roomCreated":
          setRoomCode(content);
      }
    }
  }

  function assignOnMsg(socketRef){ 
    socketRef.current.on("new user", (players, availableIcons) => {
      //TODO Replace with lobby
      let lob = lobbyRef.current
      lob.players = players
      setLobby(lob)
      console.log(lob)
      setAvailableIcons(availableIcons);
    });

    socketRef.current.on("player disconnected", (uid, newSeatNum)=>{
      handleDisconnect(uid, parseInt(newSeatNum))
    });

    socketRef.current.on("room expired",()=>{
      console.log("disconnecting")
      localStorage.removeItem('socketConnectionId');
      localStorage.removeItem('socketRoomCode');
      socketRef.current.disconnect();
      window.location.reload();
    })

    socketRef.current.on("syncRequest", (oldId, requestor) => {
      if(socketRef.current.id !== requestor){
        let newLobby = lobbyRef.current
        let oldPlayerObj = newLobby.players.find(u => u.uid === oldId)
        oldPlayerObj.uid = requestor
        oldPlayerObj.connected = true;
        setLobby(newLobby)
        //TODO on oldID Potenital Sync issue
        socketRef.current.emit("syncRequestAnswered", oldId, requestor, roomCodeRef.current, newLobby);
      }
    })

    socketRef.current.on("syncAnswered", (requestor, lobby) => {
      console.log("syncAnswered")
      if(socketRef.current.id === requestor){
        console.log(lobby)
        if(!tileMapRef.current){
          loadStaticData()
        }
        setLobby(lobby)
        setWaitingRoom(true)
        setInGame(true);
        localStorage.setItem('socketConnectionId', socketRef.current.id);
        socketRef.current.emit("in waiting room", roomCodeRef.current, socketRef.current.id)
      }
    })

    socketRef.current.on("game start", (lobbyJson)=>{
      setLobby(lobbyJson);
      console.log(lobbyJson)
      localStorage.setItem('socketConnectionId', socketRef.current.id);
      localStorage.setItem('socketRoomCode', lobbyJson['roomCode']);
    });

    /*
    socketRef.current.on("crowns added", (payload)=>{
      const userList = payload['userList']
      const amount = payload['amount']

      console.log(payload)

      let lob = lobbyRef.current
        userList.forEach((user)=>{
          if(lob['players'][user].connected){
            lob.players[user].crowns += amount
          }
        })
        setLobby(lob)
    })
    */
    socketRef.current.on("positions swapped", (payload)=>{
      const originalUserId = payload['originalUser']
      const swapPlace = payload['swapPlace']
      let originalPos;
      let swapPlayer;
      let lob = lobbyRef.current
      let originalUser = lob['players'].find(u => u.uid === originalUserId)
      setPlayersSwapped(true);
      if(swapPlace === 'start'){
        originalUser.position = 0;
        setLobby(lob)
        return;
      }

      const swappingFirst = (swapPlace === 'first')

      let swapPos = swappingFirst ? -99999 : 99999;
      lob['players'].forEach((player) => {
        if(player.connected){
          let curPos = player.position
          if(player.uid === originalUserId){
            originalPos = curPos
          }
          if((swappingFirst && curPos > swapPos) || (!swappingFirst && curPos < swapPos)){
            swapPos = player.position
            swapPlayer = player.uid
          }
        }
      })
      lob['players'].find(u => u.uid === swapPlayer).position = originalPos;
      lob['players'].find(u => u.uid === originalUserId).position = swapPos;

      setLobby(lob)
    })

    socketRef.current.on("cursed dice", (payload)=>{
      const originalUserId = payload['originalUser']
      const lob = lobbyRef.current
      const turnRule = {"rule": "Cursed Dice", "turnNum":lob['turnNum']}
      lob['players'].find(u => u.uid === originalUserId).turnRule = turnRule;
      setLobby(lob)
    })


    //289 says tileMap is null
    // Maybe make a ref to fix, but investigate why the joining player isnt getting statics loaded
    socketRef.current.on("roll result", (lob, uid, val, pos, tileData)=>{
      console.log(tileData)
      console.log(lob)
      console.log(val)
      const proj = pos+val;

      const lastTileIndex = lob.miniBoard?19:33
      let adjVal = proj>lastTileIndex? lastTileIndex-pos : val      

      if(proj<0){
        adjVal = -1*pos
      }
      let playerObj = lob['players'].find(u => u.uid === uid)
      playerObj['position'] = pos+adjVal
      const title = tileData['selectionTitle']
      const challengeData = tileData['challengeJson'];

      Object.keys(lob['spotlight']).forEach((key)=>{
        if(lob['spotlight'][key].hasOwnProperty(title)){
          const indexToRemove = lobby['spotlight'][key].indexOf(title);
          if (indexToRemove !== -1) {
            lobby['spotlight'][key].splice(indexToRemove, 1);
          }
        }
      })

      //Handle Rules
      if(tileData.hasOwnProperty("groupRule")){
        setUseOldRule(true);
        lob['previousRule'] = lob['currentRule']
        lob['currentRule'] = (tileData['title']==='Make a Rule'?
        tileData.fakeTitle : tileData['title'])
      }
      if(tileData.hasOwnProperty("turnRule")){
        playerObj['turnRule'] = {"turnNum":lob['turnNum'], "rule":tileData['title']}
      }

      let prompt;

      if(challengeData&&challengeData['varData']){
        prompt = challengeData['varData'] 
      } 
      if(prompt &&
        typeof prompt == 'object' &&
        prompt.hasOwnProperty('prompt')){
            prompt = prompt['prompt']
      } 
      if(tileData.hasOwnProperty('varData') && !challengeData){
        prompt = tileData['varData']
        if(tileData['varData'].hasOwnProperty("prompt")){
          prompt = tileData['varData']['prompt']
        }
      }
  
      if(tileData['varData'] && tileData['varData'].hasOwnProperty("promptList")){
        tileData.varData.promptList.forEach((prompt)=>{
        lob.promptHistory[tileData.categoryId].prompts.push(prompt[0])
        if(lob.promptHistory[tileData.categoryId].prompts.length >
          lob.promptHistory[tileData.categoryId].limit){
            lob.promptHistory[tileData.categoryId].prompts.shift();
        }
        })
      } else {
        if(prompt && !tileData.hasOwnProperty('noStalePrompt')){
          lob.promptHistory[tileData.categoryId].prompts.push(prompt)
          if(lob.promptHistory[tileData.categoryId].prompts.length >
            lob.promptHistory[tileData.categoryId].limit){
              lob.promptHistory[tileData.categoryId].prompts.shift();
          }
        }   
      }

      if(!tileData.hasOwnProperty('noStaleTile')){
        lob.tileHistory[tileData.categoryId].tiles.push(title)
      }
      if(lob.tileHistory.hasOwnProperty(tileData.categoryId) && lob.tileHistory[tileData.categoryId].tiles.length>
        lob.tileHistory[tileData.categoryId].limit){
          lob.tileHistory[tileData.categoryId].tiles.shift();
      }

      setLobby(lob);
      tileData['uid'] = uid;
      tileData['pos'] = pos;
      tileData['val'] = adjVal;

      setTileData(tileData);
    })


    socketRef.current.on("start minigame", (lob, uid, tileData)=>{
      console.log(tileData)
      setMiniGameStart(new Date())
      const title = tileData['selectionTitle']
      const challengeData = tileData['challengeJson'];
      
      let prompt;
      if(challengeData&&challengeData['varData']){
        prompt = challengeData['varData'] 
      } 

      Object.keys(lob['spotlight']).forEach((key)=>{
        if(lob['spotlight'][key].hasOwnProperty(title)){
          const indexToRemove = lobby['spotlight'][key].indexOf(title);
          if (indexToRemove !== -1) {
            lobby['spotlight'][key].splice(indexToRemove, 1);
          }
        }
      })

      if(prompt &&
        typeof prompt == 'object' &&
        prompt.hasOwnProperty('prompt')){
            prompt = prompt['prompt']
      } 
      if(tileData.hasOwnProperty('varData') && !challengeData){
        prompt = tileData['varData']
        if(tileData['varData'].hasOwnProperty("prompt")){
          prompt = tileData['varData']['prompt']
        }
      }

      if(tileData['varData'] && tileData['varData'].hasOwnProperty("promptList")){
        tileData.varData.promptList.forEach((prompt)=>{
        lob.promptHistory[tileData.categoryId].prompts.push(prompt[0])
        if(lob.promptHistory[tileData.categoryId].prompts.length >
          lob.promptHistory[tileData.categoryId].limit){
            lob.promptHistory[tileData.categoryId].prompts.shift();
        }
        })
      } else {
        if(prompt && !tileData.hasOwnProperty('noStalePrompt')){
          lob.promptHistory[tileData.categoryId].prompts.push(prompt)
          if(lob.promptHistory[tileData.categoryId].prompts.length >
            lob.promptHistory[tileData.categoryId].limit){
              lob.promptHistory[tileData.categoryId].prompts.shift();
          }
        }   
      }
  
      if(!tileData.hasOwnProperty('noStaleTile')){
        lob.tileHistory[tileData.categoryId].tiles.push(title)
        if(lob.tileHistory[tileData.categoryId].tiles.length>
          lob.tileHistory[tileData.categoryId].limit){
            lob.tileHistory[tileData.categoryId].tiles.shift();
        }
      }
      if(tileData.hasOwnProperty("categoryId")){
        lob['lastCategoryId'] = tileData['categoryId']
      }

      setLobby(lob);
      tileData['uid'] = uid;
      setTileData(tileData);
    })

    socketRef.current.on("alert", (msg)=>{
      setAlertData(msg+"%%%"+Date.now())
    });

    socketRef.current.on("vote results", (voteResults, voteType, lobby)=>{
        let payload = {"results":voteResults}
        let drinkVote = (voteType === 'drink')
        payload['type'] = 'vote results';
        payload['categoryTitle'] = drinkVote?' drinks':'votes';
        payload['style'] = voteType
        payload['lobby'] = lobby?lobby:lobbyRef.current;
        console.log(payload)
        if(!limbo){
          setSocketData(payload);
        }
    })

    socketRef.current.on("input results", (inputResults)=>{
      console.log(inputResults)
      let payload = {...inputResults}
      payload['cacheBuster'] = Math.random()
      if(!limbo){
        setSocketData(payload);
      }
  })
  

    socketRef.current.on("chal update", (chalData)=>{
      if(!limbo){
        setSocketData(chalData);
      }
    })

    socketRef.current.on("chance update", (chanceData)=>{
      if(!limbo){
        setSocketData(chanceData);
      }
    })

    socketRef.current.on("new turn", (newTurnSeatNum)=> {
      let lob = lobbyRef.current

      let turnPlayer = lob.players.find(u => u.seatNum === newTurnSeatNum)
      if(turnPlayer.uid === socketRef.current.id && miniGameStartRef.current){
        socketRef.current.emit("capture time", roomCodeRef.current, miniGameStartRef.current, new Date())
      }

      pushToNextTurn(parseInt(newTurnSeatNum))
    })
    /*
    socketRef.current.on("start minigame", (data)=> {
      setShowLeaders(false);
      setUseOldRule(false);

      setBannerMsg("Mini Game for 👑")

      const selectionTitle = data.selection.title;
      let lob = lobbyRef.current
      lob.miniGameHistory.push(selectionTitle)
      if(lob.miniGameHistory.length > 0){
        lob.miniGameHistory.shift();
      }
      setLobby(lob)
      setMiniGameData(data)
      setMiniGame(true);
    })
    */


    socketRef.current.on("show leaders", ()=>{
      //Process should be, 
      setShowLeaders(true);
      setBannerMsg('Game Over')
    })
 
/*
    socketRef.current.on("minigame ended", ()=> {
      setMiniGame(false)
      let newLobby = lobbyRef.current
      newLobby.roundNum++;
      setLobby(newLobby)

      pushToNextTurn()
    })
  */

    socketRef.current.on("game ended", ()=> {
      localStorage.removeItem('socketConnectionId'); 
      localStorage.removeItem('socketRoomCode');
      window.location.reload();
    })

    socketRef.current.on("roll triggered", (diceVal)=>{
      let payload = {}
      payload["type"] = "roll triggered";
      payload['diceVal'] = diceVal
      
      setSocketData(payload)
    })
    socketRef.current.on("voucher approved", (voucherCode)=>{
        console.log("ACTIVE VOUCHER:")
        console.log(voucherCode)
    })

  }

  function pushToNextTurn(newTurnSeatNum){
    //Used to update some turn meta data
    let newLobby = lobbyRef.current;
    newLobby['turnNum'] += 1;
    newLobby['eid'] += 1;
    setLobby(newLobby);

    let turnNum = newLobby['turnNum']
    if(turnNum in checkpointMsg){
      setAlertData(checkpointMsg[turnNum])
    }

    setUseOldRule(false);
    setPlayersSwapped(false);

    //Clear out any challenges, close event cards
    let payload = {"type":'end turn'}
    setSocketData(payload);

    //Pull out waiters
    if(limboRef.current || waitingRoomRef.current){
      setLimbo(false)
      setWaitingRoom(false)
      //TODO Update this on server prior
      socketRef.current.emit("end waiting room", roomCodeRef.current, socketRef.current.id)
    }

    startNewTurn(newTurnSeatNum);
  }

  function handleDisconnect(dcid, newSeatNum){
    //Update host right away
    let newLobby = lobbyRef.current;
    if(!newLobby['players']){
      return
    }
    newLobby['players'].forEach((player)=>{
      if(player.uid === dcid){
        player.connected = false;
      }
    })
    setLobby(newLobby)
    if(dcid === newLobby.turnPlayer && !showLeadersRef.current){
      pushToNextTurn(newSeatNum)
    }
  }



  function startNewTurn(newSeatNum){

    let lob = lobbyRef.current

    let turnPlayer = lob.players.find(u => u.seatNum === newSeatNum)
    /*
    while(!turnPlayer.connected){
      newSeatNum++;
      if(newSeatNum>=lob.players.length){
        newSeatNum = 0
      }
      turnPlayer = lob.players.find(u => u.seatNum === newSeatNum)
    }
    */
    let turnPlayerName = turnPlayer.name;
    lob['turnPlayer'] = turnPlayer.uid;
    lob['turnSeatNum'] = newSeatNum;

    setLobby(lob);
    setBannerMsg(`${turnPlayerName}'s ${isBackup(turnPlayerName, lob)?"t":"T"}urn${staging?"-":""}`)
  }

  function isBackup(turnPlayerName, lob){
    if(username==="Nubjug"){
      if(turnPlayerName === lob.backupHost){
        return true
      }
    }
    return false
  }

  function createResponseHandler(res){
    console.log(res)
    if(res.includes("Cache") || res.includes("Unable")){
      setErrorMsg(res)
    } else {
      setIsHost(true);
      setFloatAway(true)
      setRoomCode(res)
    }
  }

  function create(){
    if(!cleanEntries()){
      return;
    }
    if(ioServer){
      socketRef.current = io.connect(drinkRoyaleApi);
      assignOnMsg(socketRef);
      socketRef.current.emit("create room",appVersion, (res)=>createResponseHandler(res));
      //socketRef.current.emit("voucher requested")
    }
  }

  function containsNonLetters(str) {
    return /[^a-zA-Z\s]/.test(str);
  }

  function cleanEntries(){

    if(containsNonLetters(username)){
      setErrorMsg("Username can only contain letters")
      return false;
    }
    if(containsNonLetters(roomCode)){
      setErrorMsg("Room code can only contain letters")
      return false;
    }
    if(username.length === 0){
      setErrorMsg("Name required");
      return false;
    }
    if(username.length > 12){
      setErrorMsg("Name is too long");
      return false;
    }
    return true;
  }

  function validate(valid, msg){
    setFloatAway(valid);
    setValidated(valid);
    setErrorMsg(msg);
  }

  function joinRoom(isHost){
    //TODO add control bit to switch between prod socket and ws
    if(ioServer){
      socketRef.current.emit("join room", roomCode, username, isHost);
    }
    loadStaticData()
  }

  function queryRoom(event){
    event.preventDefault();

    if(!cleanEntries()){
      return;
    }
    if(!socketRef.current){
      socketRef.current = io.connect(drinkRoyaleApi);
      assignOnMsg(socketRef)
    }
    if(ioServer){
      socketRef.current.emit("query room", roomCode, username, appVersion,(res,msg)=>validate(res,msg));
    }
  }

  /*
  function sendMessage(){
    const payload ={
      content: message,
      to: currentChat.isChannel ? currentChat.chatName : currentChat.receiverId,
      sender: username,
      chatName: currentChat.chatName,
      isChannel: currentChat.isChannel
    };
    socketRef.current.emit("send message", payload);
    const newMessages = immer(messages, draft => {
      draft[currentChat.chatName].push({
        sender: username,
        content: message
      });
    });
    setMessages(newMessages);
  }
  */

  //immer duplicates an immutable obj, transforms it with new draft and can be overwrritten 
  /*
  function roomJoinCallback(incomingMessages, room){
    const newMessages = immer(messages, draft =>{
      draft[room] = incomingMessages;
    });
    setMessages(newMessages);
  }
  */


  function sendEvent(type, payload){
    socketRef.current.emit(type,roomCode,socketRef.current.id,lobby,payload);
  }

  function toggleReady(){
    socketRef.current.emit("ready change",roomCode,socketRef.current.id);
  }

  function handleUsernameChange(e){
    let trimmed = e.target.value.trim().slice(0, 12)
    if(trimmed.length > 1){
      trimmed = trimmed.charAt(0) + trimmed.slice(1).toLowerCase();
    }
 
    setUsername(trimmed);
  }

  function handleRoomCodeChange(newCode){
    setRoomCode(newCode.toUpperCase());
  }

  function clearErrorMsg(){
    setErrorMsg('');
  }

  function chooseIcon(e){
    e.preventDefault();
    socketRef.current.emit("icon change",roomCode,socketRef.current.id,e.currentTarget.id);
  }

  function handleDLvlChange(e){
    e.preventDefault();
    let newVal = parseInt(e.target.value)
    /*
    if(newVal === dLvl && dLvl !== 0){
      newVal-=1;
    } 
    */
      setDLvl(newVal);
  }

  function handleLvlNext(e){
    e.preventDefault();
    setlvlsPicked(true);
  }

  function handleSLvlChange(e){
    e.preventDefault();
    let newVal = parseInt(e.target.value)
    /*
    if(newVal === sLvl && sLvl !== 0){
      newVal-=1;
    } 
    */
    setSLvl(newVal);
  }

  function handleMapChange(e){
    e.preventDefault();
    let newVal = e.currentTarget.id
    console.log(newVal)
    setHappyHour(newVal==='miniBoard');
    console.log(newVal==='miniBoard')
    setMiniBoard(newVal==='miniBoard')
  }

  let body;

  function hostStart(e){
    e.preventDefault();
    socketRef.current.emit("host start", roomCode, dLvl, sLvl, miniBoard, backupHost);
  }

  body = useMemo(() => {
    if (limbo) {
      return <Limbo />;
    } else if (waitingRoom) {
      return <WaitingRoom handleDetatch={detatchFromLobby}/>;
    } else if (connected && !inGame) {
      window.scrollTo(0, 0);
      return (
        <>
        <Lobby
          yourId={socketRef.current ? socketRef.current.id : ''}
          allUsers={lobbyRef.current.players}
          roomCode={roomCode}
          startGame={hostStart}
          toggleReady={toggleReady}
          availableIcons={availableIcons}
          chooseIcon={chooseIcon}
          sLvl={sLvl}
          dLvl={dLvl}
          miniBoard={miniBoard}
          lvlsPicked={lvlsPicked}
          handleLvlNext={handleLvlNext}
          chooseDLvl={handleDLvlChange}
          chooseSLvl={handleSLvlChange}
          chooseMap={handleMapChange}
          chooseBackupHost={chooseBackupHost}
          isHost = {isHost}
          backupHost = {backupHost}
        />
      </>
      );
    } else if (inGame && tileMap && library && lobby && !happyHour) {
      return (
        <GameBoard
          roomCode={roomCode}
          sendEvent={sendEvent}
          bannerMsg={bannerMsg}
          lobby={lobbyRef.current}
          yourId={socketRef.current?socketRef.current.id:0}
          tileMap={tileMapRef.current}
          tileData={tileData}
          library={library}
          socketData={socketData}
          alert={alertDataRef.current}
          showLeaders={showLeaders}
          useOldRule={useOldRuleRef.current}
          splashes = {splashRef.current}
          playersSwapped = {playersSwapped}
          dLvl={dLvl}
          sLvl={sLvl}
        />
      );
    }  else if (inGame && tileMap && library && lobby && happyHour) {
      return (
        <HappyHour
          roomCode={roomCode}
          sendEvent={sendEvent}
          bannerMsg={bannerMsg}
          lobby={lobbyRef.current}
          yourId={socketRef.current?socketRef.current.id:0}
          tileMap={tileMapRef.current}
          tileData={tileData}
          library={library}
          socketData={socketData}
          alert={alertDataRef.current}
          showLeaders={showLeaders}
          useOldRule={useOldRuleRef.current}
          splashes = {splashRef.current}
          playersSwapped = {playersSwapped}
          dLvl={dLvl}
          sLvl={sLvl}
        />
      );
      
    }else {
      return (
        <JoinForm
          roomCode={roomCode}
          urlCode={urlCode}
          onRoomCodeChange={handleRoomCodeChange}
          onUsernameChange={handleUsernameChange}
          username = {username}
          create={create}
          connect={queryRoom}
          errorMsg={errorMsg}
          clearErrorMsg={clearErrorMsg}
          appVersion = {appVersion}
          floatAway = {floatAway}
          paywall = {paywall}
        />
      );
    }
  }, [
    limbo,
    waitingRoom,
    connected,
    inGame,
    roomCode,
    socketRef,
    availableIcons,
    sLvl,
    dLvl,
    lvlsPicked,
    handleLvlNext,
    handleDLvlChange,
    handleSLvlChange,
    handleMapChange,
    tileMap,
    library,
    lobby,
    tileData,
    socketData,
    alertDataRef.current,
    showLeaders,
    useOldRuleRef.current,
    bannerMsg,
    errorMsg,
    playersSwapped,
    appVersion,
    floatAway
  ]);
  function onRenderCallback(
    id, // component's "id"
    phase, // "mount" (for the initial render) or "update" (for re-renders)
    actualDuration, // time spent rendering the update
  ) {
    console.log(`Rendered ${id} in ${actualDuration} ms -${phase}`);
  }
  return (
    <div className="App">
      {body}
    </div>
  );
}



export default App;
